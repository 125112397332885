import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../store/withReducer";
import reducer from "../confirm/store/index";
import {useParams} from "react-router";
import {useNavigate} from 'react-router-dom';
import Timer from "../../Assets/timer.png";
import moment from "moment";
import {reset} from "../confirm/store/informationSlice";


export function Receipt() {
    const {useState, useMemo, useEffect} = React;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const information = useSelector(({information}) => information.information)
    const {uid} = useParams();
    const isVoucher = useSelector(({information}) => information.information.isVoucher)
    const [seconds, setSeconds] = useState(30); //sets the timer seconds.
    const dispatch = useDispatch();


    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            if (isVoucher) {
                navigate('/Voucherparkering');
            } else {
                navigate('/' + information.parking.uid);
            }
            dispatch(reset())
        }
    });

    const goBack = () => {
        if (isVoucher) {
            navigate('/Voucherparkering');
        } else {
            navigate('/' + information.parking.uid);
        }
        dispatch(reset())
    }

    function getDuration(minutes) {
        var hours = 0;
        var min = 0;
        while (minutes >= 60) {
            hours = hours + 1;
            minutes = minutes - 60;
        }
        min = minutes
        if (hours == 0) {
            return minutes + " " + t("Minutter");
        }

        if (minutes <= 0) {
            return hours + " " + t("Time(r)");
        } else {
            return hours + " " + t("Time(r)") + " " + min + " " + t("Minutter");
        }
    }


    return (
        <>
            <div>
                <div className="w-full">
                    <div className="flex justify-center mt-2 md:mt-10">
                        <p className="md:text-3xl lg:text-5xl">{t('Kvittering')}</p>
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex justify-center mt-2 md:mt-10">
                        <p className="lg:text-2xl md:text-2xl">{t('Din parkering er nu oprettet')}</p>
                    </div>
                </div>
                <div
                    className="w-full md:px-0 px-2 bg-white mt-5 pt-5 mb-5 pb-5 border-t-2 border-b-2 border-apcoablue">
                    <div className="flex justify-center">
                        <p className="lg:text-xl xs:text-base text-red-500 italic ">{t('Kontroller venligst at registreringsnummer og mobilnummer er korrekt indtastet')}</p>
                    </div>
                    <div className="flex justify-center mt-5  mb-5">
                        <div className="flex grid grid-cols-2 md:gap-x-60 gap-x-10 gap-y-0 md:gap-y-4">
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Registreringsnummer')}</p>
                            </div>
                            <div className="justify-center">
                                <p className="justify-center font-bold">{information.parking.vehicleRegistration}</p>
                            </div>
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Mobilnummer')}</p></div>
                            <div className=""><p
                                className="font-bold">{isVoucher ? information.voucher.PhoneNumber : information.phoneNumber}</p>
                            </div>
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Starttidspunkt')}</p></div>
                            {isVoucher ?
                                <div className=""><p
                                    className="font-bold">{information.voucher.StartTime ? moment(information.voucher.StartTime).format("DD-MM-yyyy HH:mm") : ""}</p>
                                </div>
                                :
                                <div className=""><p
                                    className="font-bold">{information.parking.startTime ? moment(information.parking.startTime).format("DD-MM-yyyy HH:mm") : ""}</p>
                                </div>
                            }
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Sluttidspunkt')}</p></div>
                            {isVoucher ?
                                <div className=""><p
                                    className="font-bold">{information.voucher.EndTime ? moment(information.voucher.EndTime).format("DD-MM-yyyy HH:mm") : ""}</p>
                                </div>
                                :
                                <div className=""><p
                                    className="font-bold">{information.parking.endTime ? moment(information.parking.endTime).format("DD-MM-yyyy HH:mm") : ""}</p>
                                </div>
                            }
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Gyldighed')}</p></div>
                            <div className="">
                                {isVoucher ?
                                    <p className="font-bold">{getDuration(information.period.Duration)}</p>
                                    :
                                    <p className="font-bold">{getDuration(information.parking.duration)}</p>
                                }
                            </div>
                            <div className=""><p className="lg:text-xl xs:text-base">{t('Parkerings-ID')}</p></div>
                            <div className=""><p className="font-bold">{information.parking.parkingNumber}</p></div>

                        </div>

                    </div>
                    <div className="flex justify-center mt-5">
                        <p className="lg:text-xl xs:text-base">{t('Parkeringen er gældende på følgende parkeringsplads(er)')}</p>
                    </div>
                    {
                        isVoucher ?
                            <div className="flex justify-center mt-5">
                                {!information.voucher.AllAreas ?
                                    <div className="flex flex-col items-center justify-center mt-5">
                                        {
                                            information.parking.parkingZones.map((area, index) => {
                                                return <p key={index}
                                                          className="lg:text-xl font-bold xs:text-base">{area.Address.toString()} - {t('Områdekode')} {area.number}</p>
                                            })}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            <div className="flex flex-col items-center justify-center mt-5">
                                {
                                    information.parking.parkingZones.map((zone, index) => {
                                        return <p
                                            key={index}
                                            className="lg:text-xl font-bold xs:text-base">{zone.address.toString()} - {t('Områdekode')} {zone.number}</p>
                                    })}
                            </div>


                    }
                    <div className="flex justify-center mt-5">
                        <p className="lg:text-xl italic xs:text-base">{t('Tag evt. et billede af denne skærm med din mobil.')}</p>
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex mb-6 justify-center items-center mt-0 md:mt-10">
                        <img className="mr-5" alt="lang" width={40} height={20} src={Timer}/>
                        <p className="text-2xl bold xs:text-base"> {seconds}</p>
                    </div>
                    <div className="flex mb-6 justify-center items-center mt-0 md:mt-10">
                        <button onClick={() => goBack()
                        } style={{backgroundColor: "#07395b"}}
                                className="w-3/5 md:w-2/5 bg-green-500 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button">
                            {t('Gå til forsiden')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withReducer('information', reducer)(Receipt)
